import React from 'react';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import axios from 'axios';

class App extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: ''
    }
  }

  handleSubmit(e){
    e.preventDefault();
    axios({
      method: "POST", 
      url:"http://localhost:3002/send", 
      data:  this.state
    }).then((response)=>{
      if (response.data.status === 'success') {
        alert("Message Sent."); 
        this.resetForm()
      } else if (response.data.status === 'fail') {
        alert("Message failed to send.")
      }
    })
  }

  resetForm(){
    this.setState({name: ``, email: ``, message: ``})
  }
  
  render() {
    return(
      <div className="App">
        <form id="contact-form" action="https://formspree.io/f/moqyepzb" method="POST">
        <Input name="email" id="name" type="email" style={{width: 350}} placeholder="Email Address" label="Required" inputProps={{ 'aria-label': 'Email' }} /><br></br>
        <div style={{paddingBottom: 35}} ></div>


        <Input id="message" name="message" style={{width: 500}}type="textarea" label="Required" aria-label="Comment" placeholder="Please leave us a message and we'll be in touch."/>
        <div style={{paddingBottom: 35}} ></div>
        <div class="g-recaptcha" data-sitekey="6Lc7cVMUAAAAAM1yxf64wrmO8gvi8A1oQ_ead1ys" class="form-control" ></div>
        <Button type="submit" variant="contained" style={{backgroundColor: 'black', color: 'white'}}onClick="submitToAPI(event)">
            Message
        </Button>
        </form>
      </div>
    );
  }

  onNameChange(event) {
	  this.setState({name: event.target.value})
  }

  onEmailChange(event) {
	  this.setState({email: event.target.value})
  }

  onMessageChange(event) {
	  this.setState({message: event.target.value})
  }
}

export default App;